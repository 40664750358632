import React from 'react';
import styled from 'styled-components';

const ProductItem = ({ image, name, price, originalPrice, link }) => {
  return (
    <ProductWrapper>
      <ProductImage src={image} alt={name} />
      <ProductName>{name}</ProductName>
      <ProductPriceWrapper>
        <BuyButton> <a target="_blank" href={link}>MUA NGAY</a></BuyButton>
        <PriceInfo>
          <CurrentPrice>{price}</CurrentPrice>
          <OriginalPrice>{originalPrice}</OriginalPrice>
        </PriceInfo>
      </ProductPriceWrapper>
    </ProductWrapper>
  );
};

const ProductWrapper = styled.article`
  display: flex;
  flex-direction: column;
  color: #000;
  font-family: Quicksand, sans-serif;
`;

const ProductImage = styled.img`
  aspect-ratio: 0.99;
  object-fit: cover;
  object-position: center;
  max-width: 370px;
  width: 100%;
  height: 370px;
`;

const ProductName = styled.h3`
  font-size: 20px;
  font-weight: 700;
  margin-top: 12px;
`;

const ProductPriceWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  align-items: center;
`;

const BuyButton = styled.button`
  border-radius: 30px;
  background-color: #ff0b4b;
  font-size: 20px;
  color: #fff;
  font-weight: 700;
  padding: 12px 21px;
  border: none;
  cursor: pointer;

  a{
    color:#ffffff;
  }
  @media (max-width: 991px) {
    padding: 10px 15px;
    font-size: 18px;
  }
`;

const PriceInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const CurrentPrice = styled.span`
  color: #48bf8a;
  font-size: 22px;
  font-weight: 700;
`;

const OriginalPrice = styled.span`
  color: #000;
  font-size: 22px;
  font-weight: 700;
  text-decoration: line-through;
`;

export default ProductItem;