export default function Footer() {
    return (
      <footer className="footer">
        <p>© 2024 giaygiare.store. All rights reserved.</p>
        <div className="social-links">
          <a target="_blank" href="https://www.facebook.com/profile.php?id=100085753368994">Facebook</a>
          <a target="_blank" href="https://shopee.vn/_thstore_?entryPoint=ShopBySearch&searchKeyword=_thstore_">Shoppe</a>
          <a target="_blank" href="https://x.com/Tuanthien2608">Twitter</a>
        </div>
      </footer>
    );
  }
