import React from 'react';
import styled from 'styled-components';

// Tạo array chứa các đường dẫn ảnh
const images = [
  { src: "https://ik.imagekit.io/s7qfxbeum/Screen%20Shot%202024-10-16%20at%2018.18.41.svg?updatedAt=1729139518852", category: "Nature", tag: "Flora" },
  { src: "https://ik.imagekit.io/s7qfxbeum/Screen%20Shot%202024-10-16%20at%2018.18.33.svg?updatedAt=1729139518918", category: "Nature" },
  { src: "https://ik.imagekit.io/s7qfxbeum/Screen%20Shot%202024-10-16%20at%2018.16.21.svg?updatedAt=1729139518734", category: "Art" },
  { src: "https://ik.imagekit.io/s7qfxbeum/Screen%20Shot%202024-10-16%20at%2018.18.02.svg?updatedAt=1729139518562", category: "Food" },
  { src: "https://ik.imagekit.io/s7qfxbeum/Screen%20Shot%202024-10-16%20at%2018.15.08.svg?updatedAt=1729139518553", category: "Nature" },
];

// CSS cho grid layout
const GalleryWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 300px);
  gap: 10px;
  padding: 20px;
  max-width:1440px;

  /* Grid-area để điều chỉnh kích thước và vị trí của các item */
  .item1 { grid-column: span 2; grid-row: span 2; }
  .item2 { grid-column: span 2; }
  .item3 { grid-column: span 1; grid-row: span 2; }
  .item4 { grid-column: span 2; }
  .item5 { grid-column: span 1; grid-row: span 2; }
  .item6 { grid-column: span 2; }
`;

const GalleryItem = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .category {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 14px;
  }

  .tag {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: rgba(255, 255, 255, 0.7);
    color: #333;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
  }
`;

const ImageGallery = () => {
  return (
    <GalleryWrapper>
      {images.map((image, index) => (
        <GalleryItem className={`item${index + 1}`} key={index}>
          <img src={image.src} alt={`Gallery Image ${index + 1}`} />
          {image.category && <div className="category">{image.category}</div>}
          {image.tag && <div className="tag">{image.tag}</div>}
        </GalleryItem>
      ))}
    </GalleryWrapper>
  );
};

export default ImageGallery;
