import React from 'react';
import styled from 'styled-components';
import ImageGallery from './ImageGallery';




const CustomerFeedback = () => {
  return (
    <FeedbackSection>
      <FeedbackTitle>PHẢN HỒI TỪ KHÁCH HÀNG</FeedbackTitle>
     {/* <ImageGallery/> */}
      <GroupFeed>
          <ItemFB>
              <img src="https://ik.imagekit.io/s7qfxbeum/Screen%20Shot%202024-10-16%20at%2018.18.41.svg?updatedAt=1729139518852" alt="giaygiarestore" />
          </ItemFB>
          <ItemFB>
              <img src="https://ik.imagekit.io/s7qfxbeum/Screen%20Shot%202024-10-16%20at%2018.18.33.svg?updatedAt=1729139518918" alt="giaygiarestore" />
          </ItemFB>
          <ItemFB>
              <img src="https://ik.imagekit.io/s7qfxbeum/Screen%20Shot%202024-10-16%20at%2018.16.21.svg?updatedAt=1729139518734" alt="giaygiarestore" />
          </ItemFB>
          <ItemFB>
              <img src="https://ik.imagekit.io/s7qfxbeum/Screen%20Shot%202024-10-16%20at%2018.16.30.svg?updatedAt=1729139518845" alt="giaygiarestore" />
          </ItemFB>
          <ItemFB>
              <img src="https://ik.imagekit.io/s7qfxbeum/Screen%20Shot%202024-10-16%20at%2018.18.02.svg?updatedAt=1729139518562" alt="giaygiarestore" />
          </ItemFB>
          <ItemFB>
              <img src="https://ik.imagekit.io/s7qfxbeum/Screen%20Shot%202024-10-16%20at%2018.17.52.svg?updatedAt=1729139518562" alt="giaygiarestore" />
          </ItemFB>
      </GroupFeed>
    </FeedbackSection>
  );
};

const FeedbackSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background:#ffffff;
  padding:50px 0;

  @media (max-width: 991px) {
    padding:10px 2%;
  }
`;


const GroupFeed = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap:20px;
  max-width:1400px;
  margin:auto;
  justify-content: center;

  @media (max-width: 991px) {

  }
`;

const ItemFB = styled.div`
  width:47%;


  img{
    width:100%;
  }
  @media (max-width: 991px) {
    width:47%;
  }
`;

const FeedbackTitle = styled.h2`
  color: #ff0b4b;
  font: 700 40px Quicksand, sans-serif;
  text-align:center;
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 30px;
  }
`;

const FeedbackImage = styled.img`
  aspect-ratio: 2;
  object-fit: contain;
  object-position: center;
  width: 100%;
  margin-top: 65px;
  max-width: 1654px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

export default CustomerFeedback;