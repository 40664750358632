import React from 'react';
import styled from 'styled-components';

const Aboutus = () => {
  return (
    <AboutSection>
      <HeroImage src="https://ik.imagekit.io/s7qfxbeum/banner%20khuye%CC%82%CC%81n%20ma%CC%83i%2050_%20hoka.jpg?updatedAt=1729144388065" alt="Hoka shoes showcase" />
      <AboutTitle>VỀ CHÚNG TÔI</AboutTitle>
      <AboutContent>
      <AboutImage src="https://ik.imagekit.io/s7qfxbeum/Group%201321316977.svg?updatedAt=1729135623066" alt="About us Hoka" />
        <AboutText>
            <strong>Tại ThienStore</strong> , chúng tôi tự hào mang đến cho <br />
            bạn bộ sưu tập giày Hoka mới nhất và tốt nhất. <br />
            Được thiết kế với công nghệ tiên tiến và phong <br />
            cách không thể chối từ, mỗi đôi giày Hoka đều <br />
            mang đến sự thoải mái và hiệu suất <br />
            tối ưu cho mọi bước chân !
        </AboutText>
      </AboutContent>
    </AboutSection>
  );
};

const AboutSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background:#ffffff;
  padding:0 0 50px 0;

  @media (max-width: 991px) {
    padding:10px 0;
  }
`;

const HeroImage = styled.img`
  aspect-ratio: 3;
  object-fit: contain;
  object-position: center;
  width: 100%;
  align-self: stretch;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const AboutTitle = styled.h2`
  color: #ff0b4b;
  margin-top: 60px;
  margin-bottom:30px;
  font: 700 40px Quicksand, sans-serif;
  @media (max-width: 991px) {
    margin-top: 40px;
    font-size: 30px;
    margin-bottom: 0;
  }
`;

const AboutContent = styled.div`
  width: 100%;
  max-width: 1400px;
  display: flex;
  justify-content: center;
  gap: 30px;
  padding: 0 2%;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 0;
    flex-direction: column;
    gap:10px;

  }
`;

const AboutImage = styled.img`
  aspect-ratio: 1.06;
  object-fit: contain;
  object-position: center;
  width: 34%;
  border-radius: 10px;
  @media (max-width: 991px) {
    width: 100%;
    max-width: 100%;
    margin-top: 5px;
  }
`;

const AboutText = styled.p`
  color: #000;
  align-self: stretch;
  margin: auto 0;
  font: 500 28px Quicksand, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 0px;
    font: 500 20px Quicksand, sans-serif;
  }
`;

export default Aboutus;