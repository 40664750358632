import React from 'react';
import styled from 'styled-components';
import ProductItem from './ProductItem';

const productsData = [
  {
    image: "https://giaygiare.store/wp-content/uploads/2024/06/z5579402296980_5b811aec3d4adae596bb63af2fe8ff98.jpg.webp",
    name: "Giày Chạy Bộ Nam Hoka Stinson ATR 6",
    price: "1 300 000 VNĐ",
    originalPrice: "1 750 000 VNĐ",
    link:"https://giaygiare.store/san-pham/giay-chay-bo-nam-hoka-stinson-atr-6/"
  },
  {
    image: "https://giaygiare.store/wp-content/uploads/2024/07/Giay-Chay-Bo-Nam-Hoka-Stinson-ATR-6-Xam.jpg.webp",
    name: "Giày Chạy Bộ Nam Hoka Stinson ATR 6 – Grey",
    price: "1 630 000 VNĐ",
    originalPrice: "2 000 000 VNĐ",
    link:"https://giaygiare.store/san-pham/giay-chay-bo-nam-hoka-stinson-atr-6-grey-2/"
  },
  {
    image: "https://giaygiare.store/wp-content/uploads/2024/06/Hoka-Cielo-X1-%E2%80%93-Cam-1.jpg.webp",
    name: "Giày Chạy Bộ Unisex Hoka Cielo X1 – Cam",
    price: "1 400 000 VNĐ",
    originalPrice: "2 500 000 VNĐ",
    link:"https://giaygiare.store/san-pham/giay-chay-bo-unisex-hoka-cielo-x1-cam/"
  },
  {
    image: "https://giaygiare.store/wp-content/uploads/2024/06/HOKA-STINSON-6-mau-trang-1.jpg.webp",
    name: "Giày Chạy Bộ Nam Hoka Stinson ATR 6 – White",
    price: "1 630 000 VNĐ",
    originalPrice: "2 000 000 VNĐ",
    link:"https://giaygiare.store/san-pham/giay-chay-bo-nam-hoka-stinson-atr-6-white/"
  },
  {
    image: "https://giaygiare.store/wp-content/uploads/2024/09/HOKA-MATCH-6-ALL-BLACK.jpg.webp",
    name: "Giày HOKA MATCH 6 – ALL BLACK",
    price: "1 170 000 VNĐ",
    originalPrice: "1 650 000 VNĐ",
    link:"https://giaygiare.store/san-pham/giay-hoka-match-6-all-black/"
  },
  {
    image: "https://giaygiare.store/wp-content/uploads/2024/07/Giay-Hoka-Speedgoat-5-Hombre-Gris.jpg.webp",
    name: "Giày Hoka Speedgoat 5 – Hombre Gris",
    price: "1 480 000 VNĐ",
    originalPrice: "1 850 000 VNĐ",
    link:"https://giaygiare.store/san-pham/giay-hoka-speedgoat-5-hombre-gris/"
  },

];

const ProductList = () => {
  return (
    <ProductSection>
      <ProductTitle>SẢN PHẨM NỔI BẬT CỦA HOKA</ProductTitle>
      <ProductGrid>
        {productsData.map((product, index) => (
          <ProductItem key={index} {...product} />
        ))}
      </ProductGrid>
      <a className="buy-button" target="_blank" href="https://giaygiare.store/">Xem thêm</a>
    </ProductSection>
  );
};

const ProductSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background:#ffffff;
  padding:50px 0;
  @media (max-width: 991px) {
    padding:10px 2%;
  }

  .buy-button {
    background-color: #ff0b4b;
    margin-top:2rem;
    color: #ffffff;
    border: none;
    padding: 15px 40px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #ff6347;
      color: white;
    }

    a{
      color: #ff4500;
    }
  }
`;

const ProductTitle = styled.h2`
  color: #ff0b4b;
  font: 700 40px Quicksand, sans-serif;
  text-align:center;
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 30px;
  }
`;

const ProductGrid = styled.div`
  margin-top: 56px;
  width: 1280px;
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  @media (max-width: 991px) {
    margin-top: 40px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

export default ProductList;