import React from 'react';
import styled from 'styled-components';
import FeatureItem from './FeatureItem';

const featuresData = [
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/752ef1222a1d1ab0cb102cd41265cfdd5534b18754a5f533f0798ced58c0f83d?placeholderIfAbsent=true&apiKey=2413670ec67344988842962f2a60955b",
    title: "Đệm siêu êm ái",
    description: "Cushlon và Air Zoom: Giúp tối ưu hóa lực đệm, tạo cảm giác thoải mái khi di chuyển, bảo vệ đôi chân khỏi chấn thương."
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/b0deebb2e950b51368ac126d2e108da053da3bae23970d6cd26f462999988df6?placeholderIfAbsent=true&apiKey=2413670ec67344988842962f2a60955b",
    title: "Trọng lượng siêu nhẹ",
    description: "Hoka nổi bật với các thiết kế thời thượng, hợp xu hướng và đa dạng phong cách từ thể thao, đường phố đến thời trang cao cấp."
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/3b95d087a9390403e9704401f1705d4a25b14564a6ed3564220a95764903f764?placeholderIfAbsent=true&apiKey=2413670ec67344988842962f2a60955b",
    title: "Thiết kế bảo vệ bàn chân tối ưu",
    description: "Sản phẩm được làm từ chất liệu cao cấp, có khả năng chịu mài mòn và bền bỉ trong thời gian dài."
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/752ef1222a1d1ab0cb102cd41265cfdd5534b18754a5f533f0798ced58c0f83d?placeholderIfAbsent=true&apiKey=2413670ec67344988842962f2a60955b",
    title: "Hiệu suất vượt trội trên mọi địa hình",
    description: "Giày Hoka đáp ứng nhu cầu đa dạng, từ chạy bộ, tập luyện trong phòng gym, đến các môn thể thao như bóng rổ, bóng đá."
  },
  {
    icon: "https://ik.imagekit.io/s7qfxbeum/Group%201321316976%20(1).png?updatedAt=1729018814555",
    title: "Thích hợp cho các cự ly dài",
    description: "Hoka luôn chú trọng vào cảm giác của người mang, nhờ các công nghệ giảm chấn và thiết kế hỗ trợ bàn chân trong suốt quá trình vận động."
  },
  {
    icon: "https://ik.imagekit.io/s7qfxbeum/Group%201321316976%20(5).png?updatedAt=1729019212466",
    title: "Công nghệ thoáng khí",
    description: "Được các vận động viên chuyên nghiệp trên toàn thế giới tin dùng nhờ khả năng hỗ trợ hiệu suất thể thao vượt trội."
  },

];

const Features = () => {
  return (
    <FeaturesSection>
      <FeaturesTitle>TÍNH NĂNG VƯỢT TRỘI CỦA HOKA</FeaturesTitle>
      <GroupBanner>
          <img src="https://ik.imagekit.io/s7qfxbeum/image.svg?updatedAt=1729134384945" alt="giaygiare.store - hoka" />
          <img src="https://ik.imagekit.io/s7qfxbeum/image%20(1).svg?updatedAt=1729134385216" alt="giaygiare.store - hoka" />
          <img src="https://ik.imagekit.io/s7qfxbeum/image%207.svg?updatedAt=1729134384991" alt="giaygiare.store - hoka" />
      </GroupBanner>
      <FeaturesList>
        {featuresData.map((feature, index) => (
          <FeatureItem key={index} {...feature} />
        ))}
      </FeaturesList>
    </FeaturesSection>
  );
};

const FeaturesSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background:#ffffff;
  padding:50px 0;
  margin-bottom:1rem;
  @media (max-width: 991px) {
    padding:10px 2%;
  }
`;

const GroupBanner = styled.div`
  width: 100%;
  display: flex;
  gap:30px;
  align-items: center;
  margin-bottom:1rem;
  justify-content: center;
  margin-top: 1rem;
  flex-wrap: wrap;
  @media (max-width: 991px) {

  }

  img{
    max-width:400px;
    width:100%;
  }
`;

const FeaturesTitle = styled.h2`
  color: #ff0b4b;
  text-align:center;
  font: 700 40px Quicksand, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 30px;
  }
`;

const FeaturesList = styled.div`
  display: flex;
  margin-top: 62px;
  width: 100%;
  max-width: 1538px;
  gap: 40px 100px;
  flex-wrap: wrap;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 15px;
    gap: 20px 100px;
  }
`;

export default Features;