import React from 'react';
import styled from 'styled-components';

const Bannercta = () => {
  return (
    <AboutSection>
      <AboutContent>
        <AboutText>
            <h2><strong>NHANH TAY ĐẶT HÀNG NGAY</strong></h2>
           <p>Ưu đãi giảm giá lên đến 50% , Cơ hội duy nhất trong 2024</p>
           <a className="buy-button" href="/">MUA NGAY</a>
        </AboutText>
      </AboutContent>
    </AboutSection>
  );
};

const AboutSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding:0 0 50px 0;
  background:url('https://ik.imagekit.io/s7qfxbeum/Group%201321316978.png?updatedAt=1729138047834');
  background-position: center;
  background-size: cover;
  min-height: 55vh;
background-attachment: fixed;
justify-content: center;
background-repeat: no-repeat;
  @media (max-width: 991px) {
    padding:10px 0;
    background-size: cover;
    min-height: 70vh;
    background-attachment: unset;
  }

  .buy-button {
    background-color: #fff;
    color: #ff4500;
    border: none;
    padding: 15px 40px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #ff6347;
      color: white;
    }

    a{
      color: #ff4500;
    }
  }

`;

const HeroImage = styled.img`
  aspect-ratio: 1.90;
  object-fit: contain;
  object-position: center;
  width: 100%;
  align-self: stretch;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const AboutTitle = styled.h2`
  color: #ff0b4b;
  margin-top: 60px;
  margin-bottom:30px;
  font: 700 40px Quicksand, sans-serif;
  @media (max-width: 991px) {
    margin-top: 40px;
    font-size: 30px;
    margin-bottom: 0;
  }
`;

const AboutContent = styled.div`
  width: 100%;
  max-width: 1400px;
  display: flex;
  justify-content: center;
  gap: 30px;
  padding: 0 2%;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 0;
    flex-direction: column;

  }
`;

const AboutImage = styled.img`
  aspect-ratio: 1.06;
  object-fit: contain;
  object-position: center;
  width: 34%;
  border-radius: 10px;
  @media (max-width: 991px) {
    width: 100%;
    max-width: 100%;
    margin-top: 40px;
  }
`;

const AboutText = styled.div`
  color: #ffffff;
  align-self: stretch;
  margin: auto 0;
  font: 500 28px Quicksand, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 0px;
    font: 500 20px Quicksand, sans-serif;
  }

  h2{
      font-size:50px;
      @media (max-width: 991px) {
        font-size:30px;
      }
  }
`;

export default Bannercta;