import React, { useState } from "react";
import styled from "styled-components";

// Component Header
const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Toggle menu mobile
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <HeaderContainer>
      <div className="logo"><img src="https://giaygiare.store/wp-content/uploads/2024/04/THIEN-STORE-Logo-JPG.png.webp" alt="giaygiare.store" /></div>

      {/* Biểu tượng hamburger cho mobile */}
      <HamburgerMenu onClick={toggleMenu}>
        <span />
        <span />
        <span />
      </HamburgerMenu>

      {/* Nav cho mobile và desktop */}
      <Nav isMenuOpen={isMenuOpen}>
        <a href="#about" onClick={toggleMenu}>Giới Thiệu Về Chúng Tôi</a>
        <a href="#products" onClick={toggleMenu}>Sản Phẩm</a>
        <a href="#features" onClick={toggleMenu}>Tính Năng Vượt Trội</a>
        <a href="#testimonials" onClick={toggleMenu}>Phản Hồi Từ Khách Hàng</a>
        <a href="#faq" onClick={toggleMenu}>FAQ</a>
      </Nav>

      {/* Button CTA */}
      <button className="cta"> <a target="_blank" href="https://giaygiare.store/danh-muc-san-pham/hoka/"> MUA NGAY</a></button>
    </HeaderContainer>
  );
};

// Styles cho header
const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 0 20px;
  background-color: #fff;
  position: relative;
  margin-top:4rem;

  @media (max-width: 768px) {
    margin-top:105px;
  }

  .cta {
    background-color: #ff0b4b;
    color: #fff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 22px;
    @media (max-width: 768px) {
      font-size: 15px;
      display:none;
    }

    a{
      color: #fff;
    }
  }

  .logo {
    font-size: 24px;
    font-weight: bold;
    color:#000000;

    img{
      max-width:200px;
      @media (max-width: 768px) {
      max-width: 150px;
    }
    }
  }
`;

// Styles cho biểu tượng hamburger
const HamburgerMenu = styled.div`
  display: none;
  flex-direction: column;
  cursor: pointer;

  span {
    height: 2px;
    background: black;
    margin: 4px 0;
    width: 25px;
  }

  @media (max-width: 768px) {
    display: flex;
  }
`;

// Styles cho navigation menu
const Nav = styled.nav`
  display: flex;
  a {
    margin-right: 20px;
    text-decoration: none;
    color: #000;
    font-weight:500;
    font-size: 22px;
    transition: color 0.3s;


    &:hover {
      color: #f00;
    }
  }

  @media (max-width: 768px) {
    /* Ẩn menu khi không được mở trên mobile */
    display: ${({ isMenuOpen }) => (isMenuOpen ? "flex" : "none")};
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    padding: 20px 0;

    a {
      margin: 10px 0;
      text-align: center;
      font-size: 20px;
    }
  }
`;

export default React.memo(Header);
